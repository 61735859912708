import React from "react";
import Layout from "../components/layout/Layout";
import ServicesContent from "../components/ServicesContent";
import {graphql} from "gatsby";

const Services = () => {
  return <>
      <Layout title={'Services'}>
          <ServicesContent/>
      </Layout>
  </>
}
export default Services;



export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
